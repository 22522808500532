import { MaskedInput } from './MaskedInput';

const unmaskValue = (value) => value.replace(/\D/g, '');

export const DateInput = ({ value = '', onChange, ...props }) => (
  <MaskedInput
    mask="__.__.____"
    inputMode="tel"
    unmaskValue={unmaskValue}
    {...props}
    maxLength={10}
    value={value}
    onChange={onChange}
  />
);
