import { useCallback, useState } from 'react';

import {
  backspaceKeyCode,
  firstField,
  lastField,
  validateForm,
  validateInput,
} from './helpers';

export const useDigitsInput = ({ validationMessages }) => {
  const [activeField, setActiveField] = useState(firstField);
  const [isFocused, setFocused] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const resetError = () => setError(null);

  const setNextField = useCallback((event) => {
    if (activeField === lastField) return;

    setActiveField(event.target.selectionStart);
  }, [activeField]);

  const setPrevField = useCallback(() => {
    if (activeField === firstField) return;

    setActiveField((prev) => prev - 1);
  }, [activeField]);

  const handleChange = useCallback((value, event) => {
    if (error) {
      resetError();
    }

    const lastChange = value[activeField];

    if (validateInput(lastChange)) {
      setNextField(event);
    }

    return value;
  }, [activeField, error]);

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === backspaceKeyCode) {
      setPrevField();
    }
  };

  const handleValidate = () => validateForm(validationMessages);

  const handleClick = (e) => {
    e.target.setSelectionRange(e.target.value.length, 4);
  };

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return {
    activeField,
    error,
    isLoading,
    resetError,
    setLoading,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onValidate: handleValidate,
    onClick: handleClick,
    onFocus: handleFocus,
    onBlur: handleBlur,
    focused: isFocused,
  };
};
