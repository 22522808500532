import { useCallback } from 'react';
import classNames from 'classnames/bind';

import { Title } from 'ui';
import MobidLogo from 'icons/MobidLogo.svg';
import SberPay from './sberpay.svg';
import YandexPay from './yandex.svg';
import MirPay from './mirpay.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

// eslint-disable-next-line max-len
const PAYMENT_TRIGGER = 'https://aggrmobileid.beeline.ru/authorize?client_id=pay-test&acr_values=2&nonce=374c9b19-11eb-47ff-86bc-2195f344ab81&response_type=code&state=e14832fe-db89-42fd-a6b1-c32d61b9f952&version=mc_di_r2_v2.3&redirect_uri=https%3A%2F%2Faggrmobileid.beeline.ru%2Fpayment-success&context=%D0%9F%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B4%D0%B8%D1%82%D0%B5%20%D0%B1%D1%8B%D1%81%D1%82%D1%80%D1%83%D1%8E%20%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D1%83%20%D1%87%D0%B5%D1%80%D0%B5%D0%B7%20%D0%9C%D0%BE%D0%B1%D0%B8%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20ID%20%D0%B2%20Demo%D0%9C%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD&scope=openid+mc_pay&order_sum=11&autodetect_source_ip=true';

export const FakeStorePage = () => {
  const handleClick = () => { window.location.href = PAYMENT_TRIGGER; };

  return (
    <div className={cx('component')}>
      <Title level={1} className={cx('title')}>
        Способы оплаты
      </Title>

      <div className={cx('buttons')}>
        <div className={cx('other')}>
          <YandexPay />
        </div>
        <div className={cx('other')}>
          <SberPay />
        </div>
        <div className={cx('other')}>
          <MirPay />
        </div>

        <button type="button" className={cx('button')} onClick={handleClick}>
          <MobidLogo className={cx('icon')} />
        </button>
      </div>
    </div>
  );
};
