import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { closeModal, getModalName } from 'store/modal';
import { isScreenBelow640 } from 'store/adaptivity/selectors';

import { CONFIRM_PAYMENT_MODAL as CONTENT } from 'constants/content';

import { withRenderFlag } from 'hocs/withRenderFlag';

import { usePaymentConfirmation } from 'hooks/usePaymentConfirmation';

import { Text, Title } from 'ui';
import { Modal } from '..';
import { ButtonsGroup } from '../../ButtonsGroup';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const PaymentConfirmationModalComponent = ({ isMobile, onClose, ...restProps }) => {
  const { onConfirm } = usePaymentConfirmation();

  return (
    <Modal
      className={cx('component')}
      hideCloseButton
      handleOutsideClick={false}
      animationStyle={isMobile ? 'on-bottom' : 'centered'}
      {...restProps}
    >
      <div className={cx('container')}>
        <Title className={cx('title')}>
          {CONTENT.title}
        </Title>
        <Text className={cx('description')}>
          {CONTENT.description}
        </Text>
        <ButtonsGroup
          mainText={CONTENT.buttonText}
          altText={CONTENT.altButtonText}
          onMainClick={onConfirm}
          onAltClick={onClose}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  shouldRender: getModalName(state) === props.name,
  isMobile: isScreenBelow640(state),
});

const mapDispatchToProps = {
  onClose: closeModal,
};

export const PaymentConfirmationModal = connect(mapStateToProps, mapDispatchToProps)(withRenderFlag(PaymentConfirmationModalComponent));
