import { useCallback } from 'react';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';

import { authorizationRequest } from 'api/requests';

import { ERROR_ROUTE } from 'constants/routes';
import { MSISDN_KEY, FORM_VALIDATION } from 'constants/forms';

import { composePhoneValidator, composeRequiredValidator } from 'utils/validators';

const validators = () => ({
  [MSISDN_KEY]: [composePhoneValidator(FORM_VALIDATION[MSISDN_KEY]), composeRequiredValidator(FORM_VALIDATION.required)],
});

/**
 * @typedef AuthFunctions
 * @property {function} onSubmit - Отправляет данные формы в запросе authorizationRequest и сохраняет номер телефона в sessionStorage
 * @property {function} onValidate - Валидатор для формы
 * */

/**
* Хук обрабатывает запрос на авторизацию и возвращает методы для использования в форме
 * @returns {AuthFunctions}
* */
export const useAuth = () => {
  const navigate = useNavigate();

  const { run: submit } = useRequest(authorizationRequest, {
    manual: true,
    throwOnError: true,
    onSuccess: ({ data: redirectRoute }) => {
      if (!redirectRoute) {
        throw 'No redirect URL';
      }

      window.location.href = redirectRoute;
    },
    onError: () => {
      navigate(ERROR_ROUTE);
    },
  });

  const handleSubmit = useCallback((values) => {
    sessionStorage.setItem(MSISDN_KEY, values[MSISDN_KEY]);
    submit({ msisdn: `7${values.msisdn}` });
  }, []);

  return {
    onSubmit: handleSubmit,
    onValidate: validators,
  };
};
