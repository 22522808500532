import classNames from 'classnames/bind';

import LogoSvg from './mobid-logo.svg';
import { AboutService } from './AboutService';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Footer = ({ displayAbout = true }) => (
  <footer className={cx('footer')}>
    <LogoSvg className={cx('logo')} />
    {displayAbout && <AboutService className={cx('about')} />}
  </footer>
);
