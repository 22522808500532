import classNames from 'classnames/bind';

import { useNegativeCases } from './useNegativeCases';

import { withLayout } from 'hocs/withLayout';

import { Text, Title } from 'ui';
import { ButtonsGroup, DocumentLink, ThemedImage } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const NegativePage = () => {
  const { content, returnToForm, completeData } = useNegativeCases();

  return (
    <>
      <ThemedImage
        name={content.icon}
        alt=""
        width="120"
        height="120"
      />

      <Title level={1} className={cx('title')}>
        {content.title}
      </Title>

      {content.texts ? content.texts.map((textWithLink, index) => (
        <div key={index.toString()} className={cx('text')}>
          <DocumentLink
            {...textWithLink}
          />
        </div>
      )) : (
        <>
          <Text className={cx('text')}>
            {content.text}
          </Text>

          <ButtonsGroup
            mainText={content.buttonAccept}
            altText={content.buttonDecline}
            onMainClick={completeData}
            onAltClick={returnToForm}
          />
        </>
      )}
    </>
  );
};

export const Negative = withLayout()(NegativePage);
