import { useNavigate, useSearchParams } from 'react-router-dom';

import { getCookie } from 'utils/cookie';

import { useOnMount } from 'hooks/useOnMount';

import { DATA_COMPLETION } from 'constants/routes';
import { SITE_REDIRECT_URL_COOKIE, SITE_URL_COOKIE, STATUS_INFO_QUERY } from 'constants/const';

import { getContent, STATUS_INFO } from './helpers';

export const useNegativeCases = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const status = query.get(STATUS_INFO_QUERY);
  const content = getContent(status);

  useOnMount(() => {
    const redirectUrl = getCookie(SITE_REDIRECT_URL_COOKIE) || getCookie(SITE_URL_COOKIE);

    if (status === STATUS_INFO.WRONG_CLAIMS) return;

    if (redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 5000);
    }
  });

  const completeData = () => {
    navigate(DATA_COMPLETION);
  };

  const returnToForm = () => {
    window.location.href = getCookie(SITE_REDIRECT_URL_COOKIE);
  };

  return {
    content,
    completeData,
    returnToForm,
  };
};
