import { FinalForm } from 'forms/FinalForm';
import { FFCheckbox } from 'forms';
import { Button, Text } from 'ui';

import styles from './styles.pcss';

import { NUMBERS_LIST_UPDATE } from 'constants/content';

export const Form = ({ numbers, onSuccess }) => {
  const handleSubmit = (values) => {
    console.log('submit', values);
    onSuccess();
  };

  const handleChange = (values) => {
    console.log('values', values);
  };

  return (
    <FinalForm
      onSubmit={handleSubmit}
      values={{}}
      onChange={handleChange}
    >
      {numbers.map((number) => (
        <FFCheckbox
          className={styles.checkbox}
          id={`number-${number}`}
          name={`number-${number}`}
          key={number}
        >
          <Text size="m" className={styles.checkboxLabel}>
            {number}
          </Text>
        </FFCheckbox>
      ))}

      <Button type="submit" className={styles.button}>
        {NUMBERS_LIST_UPDATE.saveButton}
      </Button>
    </FinalForm>
  );
};
