import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getIsLoading } from 'store/global';

import Logo from '../logo.svg';

import { LoadingMessage } from '../LoadingMessage';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _Mobile = ({ isLoading, children }) => (
  <>
    <Logo className={cx('logo')} />

    <div className={cx('component')}>
      <LoadingMessage shouldRender={isLoading} />

      <div className={cx('children', { loading: isLoading })}>
        {children}
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
});

export const Mobile = connect(mapStateToProps)(_Mobile);
