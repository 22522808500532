import classNames from 'classnames/bind';

import { Timer } from '../Timer';
import TimerIcon from 'icons/timer.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ButtonWithTimer = ({ message, button, className }) => {

  return (
    <div className={cx('component', className)}>
      {button}

      <Timer
        className={cx('timer')}
        icon={<TimerIcon className={cx('icon')} />}
        message={message}
        textClassName={cx('text')}
      />
    </div>
  );
};
