import { useRequest } from 'ahooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePollingConfirmation } from 'hooks/usePollingConfirmation';

import { confirmationRequest } from 'api/requests';

import { setLoading } from 'store/global';
import { getCookie } from 'utils/cookie';

import { CODE_VERIFICATION_ROUTE } from 'constants/routes';
import { AUTH_REG_ID_KEY, SITE_CONSENT_WITH_OTP } from 'constants/const';
import { MSISDN_KEY } from 'constants/forms';

export const useConfirmationRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shouldRedirectToEnterVerifyCode = getCookie(SITE_CONSENT_WITH_OTP)?.toLowerCase() === 'true';
  const { startPolling } = usePollingConfirmation();

  const { run: submit, error } = useRequest(confirmationRequest, {
    manual: true,
    throwOnError: true,
    onError: () => { dispatch(setLoading(false)); },
    onSuccess: ({ data }) => {
      if (shouldRedirectToEnterVerifyCode) {
        sessionStorage.setItem(AUTH_REG_ID_KEY, data[AUTH_REG_ID_KEY]);
        sessionStorage.setItem(MSISDN_KEY, data[MSISDN_KEY]);
        navigate(CODE_VERIFICATION_ROUTE);
      } else {
        startPolling(data[AUTH_REG_ID_KEY]);
      }
    },
  });

  return {
    requestConfirmation: submit,
    isConfirmationError: error,
  };
};
