import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { openModal } from 'store/modal';
import { getSerializedDateValue, resetForm } from 'store/form';

import { DateInput } from '../DateInput';
import Calendar from 'icons/calendar.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const CustomDateInputComponent = ({
  swiperValue,
  onChange,
  onOpenModal,
  onResetSwiperValue,
  name,
  className,
  ...props
}) => {
  /* Следит за изменением значения даты из global store, чтобы обновить данные в FinalForm */
  useEffect(() => {
    if (swiperValue) {
      onChange(swiperValue);
    }
  }, [swiperValue]);

  const handleInputChange = useCallback((val, event) => {
    if (swiperValue) {
      onResetSwiperValue();
    }
    onChange(val, event);
  }, [swiperValue, onChange]);

  return (
    <div className={cx('container', className)}>
      <DateInput {...props} onChange={handleInputChange} />

      <Calendar className={cx('icon')} onClick={() => onOpenModal(name)} />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  swiperValue: getSerializedDateValue(state, props.name),
});

const mapDispatchToProps = {
  onOpenModal: openModal,
  onResetSwiperValue: resetForm,
};

export const CustomDateInput = connect(mapStateToProps, mapDispatchToProps)(CustomDateInputComponent);
