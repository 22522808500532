import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { CODE_VERIFICATION } from 'constants/content';
import { CODE_TIMEOUT_ROUTE } from 'constants/routes';

import { fields } from './helpers';

import { useDigitsInput } from './useDigitsInput';
import { useTimer } from 'hooks/useTimer';

import TimerIcon from 'icons/timer.svg';

import { Button, Text } from 'ui';
import { FinalForm } from 'forms/FinalForm';
import { Timer } from '../Timer';
import { Digit, Digits, FFDigitInput } from './components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const CheckDigitsForm = ({ onSubmit, verificationError }) => {
  const navigate = useNavigate();

  const {
    resetError,
    onChange,
    onKeyDown,
    onValidate,
    onClick,
    activeField,
    error,
    isLoading,
    focused,
    onFocus,
    onBlur,
  } = useDigitsInput({ validationMessages: CODE_VERIFICATION.validationMessages });
  const { timeMessage, time } = useTimer(70);

  const handleSubmit = (code) => {
    if (error) {
      resetError();
    }

    onSubmit(code);
  };

  useEffect(() => {
    if (!time) {
      navigate(CODE_TIMEOUT_ROUTE);
    }
  }, [time]);

  return (
    <FinalForm
      className={cx('form')}
      name="checkDigits"
      isValidateOnSubmit
      onSubmit={handleSubmit}
      getValidators={onValidate}
    >
      {({ values, submitErrors, valid }) => (
        <>
          <Digits isLoading={isLoading}>
            {fields.map((field) => (
              <Digit key={field} className={cx('field')} isFocused={focused} isActive={activeField === field}>
                {values?.digits?.[field]}
              </Digit>
            ))}

            <FFDigitInput
              name="digits"
              className={cx('input')}
              onChangeProxy={onChange}
              onKeyDownProxy={onKeyDown}
              onClick={onClick}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Digits>

          {(submitErrors?.digits || error || verificationError) && (
            <Text isError className={cx('error')}>
              {submitErrors?.digits || error || verificationError}
            </Text>
          )}

          <Button
            className={cx('button')}
            type="submit"
            disabled={isLoading || !valid}
          >
            {CODE_VERIFICATION.buttonText}
          </Button>

          <Timer
            className={cx('timer')}
            time={time}
            message={timeMessage}
            icon={<TimerIcon className={cx('icon')} />}
          />
        </>
      )}
    </FinalForm>
  );
};
