import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from '../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Button = ({
  id,
  type,
  href,
  icon,
  onClick,
  isInline,
  disabled,
  children,
  isLoading,
  styleType,
  ariaLabel,
  className,
}) => {
  const onButtonClick = useCallback((e) => {
    if (href) {
      e.preventDefault();
      window.location.href = href;
    }

    onClick?.(e);
  }, [href, onClick]);

  return (
    <button
      id={id}
      type={type}
      onClick={onButtonClick}
      aria-label={ariaLabel}
      disabled={disabled || isLoading}
      className={cx('component', styleType, className)}
      style={{ display: isInline ? 'inline-block' : 'block' }}
    >
      {icon}

      <Text className={cx('text')} size="m" isInline isBold500 isCentering>
        {isLoading ? 'Загрузка...' : children}
      </Text>
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  styleType: 'primary',
};

Button.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  styleType: PropTypes.oneOf(['primary', 'secondary', 'alternative']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
