import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getLoadingMessage } from 'store/global';

import { withRenderFlag } from 'hocs/withRenderFlag';

import { Text } from 'ui';
import { Preloader } from '../../../Preloader';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const _LoadingMessage = ({ loadingMessage }) => (
  <div className={cx('preloader')}>
    <Preloader />

    {loadingMessage && (
      <Text className={cx('loading-message')} isCentering>
        {loadingMessage}
      </Text>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  loadingMessage: getLoadingMessage(state),
});

export const LoadingMessage = compose(
  withRenderFlag,
  connect(mapStateToProps),
)(_LoadingMessage);
