import classNames from 'classnames/bind';

import { CODE_VERIFICATION as CONTENT } from 'constants/content';

import { useCodeVerification } from './useCodeVerification';

import { withLayout } from 'hocs/withLayout';

import { CheckDigitsForm } from 'components';
import { Text, Title } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const CodeVerificationPage = () => {
  const { onSubmit, ctn, error } = useCodeVerification();

  return (
    <>
      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <Text className={cx('description')}>
        {`${CONTENT.description} ${ctn}`}
      </Text>

      <CheckDigitsForm onSubmit={onSubmit} verificationError={error} />

      <Text className={cx('legal')} size="s" isSecondary>
        {CONTENT.legal}
      </Text>
    </>
  );
};

export const CodeVerification = withLayout()(CodeVerificationPage);
