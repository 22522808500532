import {
  FINAL,
  CODE_TIMEOUT,
  CODE_FAIL,
  EXPIRED,
} from 'constants/content';
import { RESULT_KEY } from 'constants/const';

export const getContent = (key) => {
  switch (key) {
    case RESULT_KEY.SUCCESS:
      return FINAL.POSITIVE;

    case RESULT_KEY.TIMEOUT:
      return FINAL.NEGATIVE;

    case RESULT_KEY.CODE_TIMEOUT:
      return CODE_TIMEOUT;

    case RESULT_KEY.CODE_FAIL:
      return CODE_FAIL;

    case RESULT_KEY.EXPIRED:
      return EXPIRED;
  }
};
