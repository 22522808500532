import { lazy, Suspense } from 'react';
import { compose } from 'redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { initializeStore } from 'store';
import { breakpoints, setBreakpoint } from 'store/adaptivity';

import { MODALS } from 'constants/const';

import { DateSwiperModal, ErrorBoundary, PaymentConfirmationModal, Preloader } from 'components';

import { Confirmed } from 'pages/Confirmed';
import { Negative } from 'pages/Negative';
import { UpdateRequest } from 'pages/UpdateRequest';
import { DataUpdate } from 'pages/DataUpdate';
import { NumbersListUpdate } from 'pages/NumbersListUpdate';
import { DataCompletion } from 'pages/DataCompletion';
import { BirthDateVerification } from 'pages/BirthDateVerification';
import { CodeVerification } from 'pages/CodeVerification';
import { Result } from 'pages/Result';
import { OutdatedLinkErrorPage } from 'pages/OuddatedLinkError';

import {
  // PaymentMethod,
  ConfirmPayment,
  FakeStorePage,
  PaymentError,
  PaymentSuccess,
  // AddPaymentMethod,
} from 'pages/Payment';

import { AuthStart, ConfirmAuth, AuthResult } from 'pages/Authorization';

import {
  CONFIRM_ROUTE,
  DATA_UPDATE,
  NUMBERS_LIST_UPDATE,
  NEGATIVE_ROUTE,
  UPDATE_REQUEST,
  DATA_COMPLETION,
  // PAYMENT_METHOD,
  CONFIRM_PAYMENT,
  PAYMENT_SUCCESS,
  // ADD_PAYMENT_METHOD,
  BIRTHDATE_VERIFICATION_ROUTE,
  CODE_VERIFICATION_ROUTE,
  COMPLETE_ROUTE,
  AUTH_START,
  CONFIRM_AUTH,
  AUTH_RESULT_ROUTE,
  FAKE_STORE_PAGE,
  PAYMENT_ERROR,
  OUTDATED_LINK_ERROR,
  UPDATE_PD, ERROR_ROUTE,
} from 'constants/routes';

import { withHMR } from 'hocs/withHMR';
import { withAdaptivity } from 'hocs/withAdaptivity';
import { withReduxStore } from 'hocs/withReduxStore';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const UpdatePD = lazy(() => import(/* webpackChunkName: "UpdatePD" */ 'b2c/pages/UpdatePD'));
const DefaultError = lazy(() => import(/* webpackChunkName: "Error" */ 'pages/DefaultError'));

export const App = () => (
  <div className={cx('container')}>
    <BrowserRouter>
      <Suspense fallback={<Preloader className={cx('preloader')} />}>
        <ErrorBoundary>
          <Routes>
            <Route path={ERROR_ROUTE} element={<DefaultError />} />
            <Route path={UPDATE_REQUEST} element={<UpdateRequest />} />
            <Route path={UPDATE_PD} element={<UpdatePD />} />
            <Route path={DATA_UPDATE} element={<DataUpdate />} />
            <Route path={NUMBERS_LIST_UPDATE} element={<NumbersListUpdate />} />
            <Route path={CONFIRM_ROUTE} element={<Confirmed />} />
            <Route path={NEGATIVE_ROUTE} element={<Negative />} />
            <Route path={COMPLETE_ROUTE} element={<Result />} />
            <Route path={DATA_COMPLETION} element={<DataCompletion />} />
            <Route path={BIRTHDATE_VERIFICATION_ROUTE} element={<BirthDateVerification />} />
            <Route path={CODE_VERIFICATION_ROUTE} element={<CodeVerification />} />
            <Route path={OUTDATED_LINK_ERROR} element={<OutdatedLinkErrorPage />} />

            {/* Payment Routes */}
            {/* <Route path={PAYMENT_METHOD} element={<PaymentMethod />} /> */}
            <Route path={CONFIRM_PAYMENT} element={<ConfirmPayment />} />
            <Route path={PAYMENT_SUCCESS} element={<PaymentSuccess />} />
            <Route path={PAYMENT_ERROR} element={<PaymentError />} />
            {/* <Route path={ADD_PAYMENT_METHOD} element={<AddPaymentMethod />} /> */}

            <Route path={FAKE_STORE_PAGE} element={<FakeStorePage />} />

            {/* Authorization Routes */}
            <Route path={AUTH_START} element={<AuthStart />} />
            <Route path={CONFIRM_AUTH} element={<ConfirmAuth />} />
            <Route path={AUTH_RESULT_ROUTE} element={<AuthResult />} />

            <Route path="*" element={<Navigate to={ERROR_ROUTE} />} />
          </Routes>

          <DateSwiperModal name={MODALS.BIRTH_DATE} />
          <DateSwiperModal name={MODALS.NATIONAL_IDENTIFIER_DATE} />
          <PaymentConfirmationModal name={MODALS.CONFIRM_PAYMENT} />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </div>
);

export const AppHMR = compose(
  withReduxStore(initializeStore({ name: 'MOBID::AGGREGATOR' })),
  withAdaptivity({ breakpoints, setBreakpoint }),
  withHMR(module),
)(App);
