import classNames from 'classnames/bind';

import { CONFIRM_PAYMENT as CONTENT } from 'constants/content';

import { withLayout } from 'hocs/withLayout';

import { usePaymentConfirmation } from 'hooks/usePaymentConfirmation';
import { useWillMount } from 'hooks/useWllMount';
import { useOnMount } from 'hooks/useOnMount';

import { Detail } from '../components/Detail';
import { BalanceWarning } from '../components/BalanceWarning';

import { Button, Text, Title } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ConfirmPage = () => {
  const { onClick, data, onWillMount, requestData } = usePaymentConfirmation();

  useWillMount(onWillMount);
  useOnMount(requestData);

  const insufficientBalanceError = data?.insufficient_balance;

  return (
    <div className={cx('component')}>
      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <div className={cx('details')}>
        {data && Object.keys(data).map((key, index) => (
          data[key] && CONTENT.details[key] && (
            <Detail
              key={index.toString()}
              label={CONTENT.details[key].text}
              tooltip={CONTENT.details[key]?.tooltip}
              hideValue={!data[key]?.error && CONTENT.details[key].hideValue}
              className={cx('detail')}
            >
              <div className={cx('content')}>
                {data[key]?.error ? (
                  <BalanceWarning className={cx('warning')} />
                ) : (
                  <>
                    <Text size="m" isError={key === 'balance' && insufficientBalanceError}>
                      {data[key]}
                    </Text>

                    {CONTENT.details[key].icon && (
                      <img alt="" src={CONTENT.details[key].icon} />
                    )}
                  </>
                )}
              </div>
            </Detail>
          )
        ))}
      </div>

      <Button className={cx('button')} onClick={onClick} disabled={insufficientBalanceError}>
        {CONTENT.buttonText}
      </Button>
    </div>
  );
};

export const Confirm = withLayout(false, false)(ConfirmPage);
