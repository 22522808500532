import { SUBSCRIBER_INFO } from 'constants/forms';
import {
  FFAuthorityCodeInput,
  FFCustomDateInput,
  FFInput,
  FFPassportInput,
  FFSelect,
} from 'forms';

export const DynamicFormField = ({ field, isBeeline, ...restProps }) => {
  switch (field) {
    case SUBSCRIBER_INFO.BIRTH_DATE:
      return <FFCustomDateInput {...restProps} />;

    case SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE:
      return <FFCustomDateInput {...restProps} />;

    case SUBSCRIBER_INFO.SEX:
      return <FFSelect {...restProps} />;

    case SUBSCRIBER_INFO.DOCUMENT_TYPE:
      return <FFSelect {...restProps} />;

    case SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE:
      return <FFAuthorityCodeInput {...restProps} />;

    case SUBSCRIBER_INFO.NATIONAL_IDENTIFIER:
      return <FFPassportInput {...restProps} />;

    default:
      return <FFInput {...restProps} />;
  }
};
