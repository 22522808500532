/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { CustomPropTypes } from 'utils/react';
import { useEventListener } from 'hooks/useEventListener';
import Svg from './i.svg';
import { Text } from '../Text';
import { calculateInitialBias, calculateBiasDiff } from './helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Tooltip = ({
  label,
  text,
  width,
  onShow,
  position,
  children,
  className,
  contentPosition,
}) => {
  const contentContainer = React.useRef(null);
  const [bias, setBias] = React.useState(calculateInitialBias(width, position));

  const recalculateBias = useCallback(() => {
    const biasDiff = calculateBiasDiff(contentContainer.current);
    setBias((prevValue) => prevValue + biasDiff);
  }, []);

  const calculatePosition = () => {
    onShow?.();

    recalculateBias();
  };

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  useEventListener('resize', recalculateBias, window);

  return (
    <span
      tabIndex={0}
      role="button"
      onClick={handleClick}
      aria-label="Подробнее"
      className={cx('component', className)}
      onFocus={calculatePosition}
      onMouseEnter={calculatePosition}
      onTouchStart={calculatePosition}
    >
      <div className={cx('tooltip', { label })}>
        {label || <Svg className={cx('svg')} />}
      </div>

      <div className={cx('arrow', contentPosition)} />

      <div
        ref={contentContainer}
        className={cx('container', contentPosition)}
        style={{
          width: `${width}px`,
          transform: `translateX(${bias}px)`,
        }}
      >
        <Text className={cx('text')} isInverse>
          {text || children}
        </Text>
      </div>
    </span>
  );
};

Tooltip.defaultProps = {
  width: 300,
  position: null,
};

Tooltip.propTypes = {
  text: PropTypes.string,
  onShow: PropTypes.func,
  width: PropTypes.number,
  className: PropTypes.any,
  children: CustomPropTypes.children,
  position: PropTypes.oneOf(['left', 'center', 'right']),
  /* TODO: do refactor (add another values) */
  contentPosition: PropTypes.oneOf(['top']),
};
