import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const FieldCaption = ({ hint, error, tooltip }) => (
  <Text
    size="s"
    isError={!!error}
    isSecondary={!error}
    className={cx('component')}
  >
    {error || hint}
    {tooltip && (
      <Tooltip text={tooltip} contentPosition="top" />
    )}
  </Text>
);

FieldCaption.propTypes = {
  hint: PropTypes.string,
  error: PropTypes.string,
  tooltip: PropTypes.string,
};
