import { DAYS, MONTHS, YEARS } from 'constants/dates';
import { DateTime } from 'luxon';
import { deserializeDate } from 'utils/format-string';

export const valuesWithFiller = {
  months: ['', '', ...MONTHS, '', ''],
  years: ['', '', ...YEARS, '', ''],
  days: ['', '', ...DAYS, '', ''],
};

/* Приводит значения каждого слайдера к общему формату даты */
export const formatDate = (dateObject) => {
  if (!dateObject || !dateObject.month?.index) return;

  const { year, month, day } = dateObject;

  return new Date(year, month.index - 1, day);
};

/* Checks if date is existing at all and age is more than 18 y.o. */
export const validate = (value) => {
  if (!value) return;

  let dataObject;

  if (typeof value === 'string') {
    if (value.length < 8) return;

    dataObject = DateTime.fromObject(deserializeDate(value));
  } else {
    dataObject = DateTime.fromObject({
      month: value.month?.index,
      year: value.year,
      day: value.day,
    });
  }

  const isExistingDate = dataObject.isValid;
  const isFuture = dataObject.diff(DateTime.now(), 'day').days > 0;

  /* Пока убрали проверку на 18 лет */
  const isTooYoung = dataObject.diff(DateTime.now(), 'years').years > -18;

  return isExistingDate && !isFuture; // && !isTooYoung;
};

export const getInitialSlides = (days, years) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();

  return {
    day: days.indexOf(day),
    month: month + 2,
    // Текущая дата -18 лет
    year: years.indexOf(year) - 18,
  };
};