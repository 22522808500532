import classNames from 'classnames/bind';
import {
  SUBSCRIBER_INFO,
  UPDATE_FORM,
  PERSONAL_DATA_CHECKBOX,
  FORM_VALIDATION,
  PROMO_MESSAGES_CHECKBOX,
} from 'constants/forms';
import { BIRTH_DATE_VERIFICATION as CONTENT } from 'constants/content';
import { SITE_ADVERT_CONSENT } from 'constants/const';

import { composeCheckedValidator, composeDateValidator, composeRequiredValidator } from 'utils/validators';
import { getCookie } from 'utils/cookie';

import { useBirthdateVerification } from './useBirthdateVerification';

import { withLayout } from 'hocs/withLayout';

import { FFCheckbox, FFCustomDateInput } from 'forms';
import { FinalForm } from 'forms/FinalForm';
import { Button, Text, Title } from 'ui';
import { DocumentLink } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const DEFAULT_VALUES = {
  [PERSONAL_DATA_CHECKBOX]: false,
  [PROMO_MESSAGES_CHECKBOX]: true,
};

const validators = () => ({
  [PERSONAL_DATA_CHECKBOX]: [composeCheckedValidator(CONTENT.validateMsg.required)],
  [SUBSCRIBER_INFO.BIRTH_DATE]: [composeRequiredValidator(FORM_VALIDATION.required), composeDateValidator(FORM_VALIDATION.date)],
});


const BirthDateVerificationPage = () => {
  const { onSubmit } = useBirthdateVerification();
  const displayAdvert = getCookie(SITE_ADVERT_CONSENT).toLowerCase() !== 'false';

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <>
      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <Text className={cx('description')}>
        {CONTENT.description}
      </Text>

      <FinalForm
        onSubmit={handleSubmit}
        values={DEFAULT_VALUES}
        getValidators={validators}
      >
        {({ invalid }) => (
          <>
            <FFCustomDateInput {...UPDATE_FORM[SUBSCRIBER_INFO.BIRTH_DATE]} className={cx('date-input')} />

            <FFCheckbox name={PERSONAL_DATA_CHECKBOX} id="personal-data" className={cx('checkbox-wrapper')}>
              <DocumentLink
                link={CONTENT.personalDataCheckbox.link}
                linkText={CONTENT.personalDataCheckbox.linkText}
                textBeforeLink={CONTENT.personalDataCheckbox.textBeforeLink}
              />
            </FFCheckbox>

            {displayAdvert && (
              <FFCheckbox name={PROMO_MESSAGES_CHECKBOX} id="promo-messages" className={cx('checkbox-wrapper')}>
                <Text>
                  {CONTENT.promoMessagesCheckbox.text}
                </Text>
              </FFCheckbox>
            )}

            <Button type="submit" className={cx('button')} disabled={invalid}>
              {CONTENT.buttonText}
            </Button>
          </>
        )}
      </FinalForm>
    </>
  );
};

export const BirthDateVerification = withLayout()(BirthDateVerificationPage);
