import classNames from 'classnames/bind';

import { Text } from '../../../Text';
import ArrowSVG from 'icons/arrowUp.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const PseudoInput = ({
  isError,
  label,
  placeholder,
  value,
  displayValue,
  onClick,
  isOpen,
}) => (
  <>
    <input className={cx('input')} readOnly value={value} data-invalid={isError} />
    <button type="button" className={cx('component', value && 'hasValue', { isError })} onClick={onClick}>
      {label && value && (
        <Text className={cx('label')} size="s">
          {label}
        </Text>
      )}

      {placeholder && !value && (
        <Text className={cx('text', 'placeholder')}>
          {placeholder}
        </Text>
      )}

      {value && (
        <Text className={cx('text')}>
          {displayValue}
        </Text>
      )}

      <ArrowSVG className={cx('arrow-icon', { isOpen })} />
    </button>
  </>
);
