import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { IS_MEGAFON, SITE_ADVERT_CONSENT, SITE_CONSENT_WITH_KYC } from 'constants/const';
import { BIRTHDATE_VERIFICATION_ROUTE } from 'constants/routes';
import { CONFIRM_PAGE_CONTENT as CONTENT } from 'constants/content';
import { PROMO_MESSAGES_CHECKBOX, PERSONAL_DATA_CHECKBOX, CODE, CONFIRM_ID } from 'constants/forms';

import { getPersonalDataValidator } from 'utils/form';
import { getCookie } from 'utils/cookie';

import { useOnMount } from 'hooks/useOnMount';
import { useConfirmationRequests } from './useConfirmationRequests';

import { withLayout } from 'hocs/withLayout';

import { FFCheckbox } from 'forms';
import { Title, Text, Button } from 'ui';
import { DocumentLink } from 'components';
import { FinalForm } from 'forms/FinalForm';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ConfirmedPage = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { requestConfirmation, isConfirmationError } = useConfirmationRequests();
  const shouldRedirectToBirthDate = query.get(IS_MEGAFON) || getCookie(SITE_CONSENT_WITH_KYC).toLowerCase() !== 'false';
  const displayAdvert = getCookie(SITE_ADVERT_CONSENT).toLowerCase() !== 'false';

  const handleSubmit = (values) => requestConfirmation({
    [CODE]: query.get(CODE),
    [CONFIRM_ID]: query.get(CONFIRM_ID),
    isConsent: values[PROMO_MESSAGES_CHECKBOX],
  });

  useOnMount(() => {
    if (shouldRedirectToBirthDate) {
      sessionStorage.setItem(CODE, query.get(CODE));
      navigate(BIRTHDATE_VERIFICATION_ROUTE);
    }
  });

  return (
    <>
      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <Text>
        {CONTENT.description}
      </Text>

      <FinalForm
        values={{ [PROMO_MESSAGES_CHECKBOX]: true }}
        getValidators={getPersonalDataValidator(CONTENT.validateMsg)}
        onSubmit={handleSubmit}
      >
        {({ invalid, hasSubmitErrors }) => (
          <>
            <FFCheckbox name={PERSONAL_DATA_CHECKBOX} id="personal-data" className={cx('checkbox-wrapper')}>
              <DocumentLink
                link={CONTENT.personalDataCheckbox.link}
                linkText={CONTENT.personalDataCheckbox.linkText}
                textBeforeLink={CONTENT.personalDataCheckbox.textBeforeLink}
              />
            </FFCheckbox>
            {displayAdvert && (
              <FFCheckbox name={PROMO_MESSAGES_CHECKBOX} id="promo-messages" className={cx('checkbox-wrapper')}>
                <Text>
                  {CONTENT.promoMessagesCheckbox.text}
                </Text>
              </FFCheckbox>
            )}

            {(hasSubmitErrors || isConfirmationError) && (
              <Text isError className={cx('error')}>
                {CONTENT.submitError}
              </Text>
            )}

            <Button type="submit" className={cx('button')} disabled={invalid && !hasSubmitErrors}>
              {CONTENT.buttonText}
            </Button>
          </>
        )}
      </FinalForm>
    </>
  );
};

export const Confirmed = withLayout()(ConfirmedPage);
