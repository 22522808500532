import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { subscriberInfoRequest } from 'api/requests';

import { resetForm } from 'store/form';

import { SAVE_BUTTON } from 'constants/content';
import { EXPIRED_ROUTE } from 'constants/routes';
import { UPDATE_FORM } from 'constants/forms';

import { formValidators, formFields, redirectUrl, timeMessage, TEXTS } from './helpers';

import { withLayout } from 'hocs/withLayout';

import { useTimer } from 'hooks/useTimer';

import { ButtonWithTimer, DynamicForm, DynamicFormField } from 'components';
import { Button, Text, Title } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const DataCompletionComponent = ({ onResetForm }) => {
  const navigate = useNavigate();

  const { time } = useTimer(600);

  useEffect(() => {
    if (!time) {
      document.forms[0].requestSubmit();
      navigate(EXPIRED_ROUTE);
    }
  }, [time]);

  const { run: handleSubmit } = useRequest(subscriberInfoRequest, {
    manual: true,
    onSuccess: () => {
      onResetForm();

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
  });

  return (
    <div className={cx('component')}>
      <Title level={1} className={cx('title')}>
        {TEXTS.title}
      </Title>

      <Text className={cx('text')}>
        {TEXTS.text}
      </Text>

      <DynamicForm
        className={cx('inputs')}
        wrapperClassName={cx({ wrapper: formFields.length > 5 })}
        formFields={formFields}
        onSubmit={handleSubmit}
        defaultValues={{}}
        validators={formValidators}
        button={(
          <ButtonWithTimer
            className={cx('button')}
            message={`До завершения сеанса осталось ${timeMessage(time)}`}
            button={(
              <Button type="submit">
                {SAVE_BUTTON}
              </Button>
            )}
          />
        )}
      >
        {(field, index) => (
          <DynamicFormField
            field={field}
            key={index.toString()}
            className={cx('input')}
            {...UPDATE_FORM[field]}
          />
        )}
      </DynamicForm>
    </div>
  );
};

const mapDispatchToProps = {
  onResetForm: resetForm,
};

export const DataCompletion = connect(null, mapDispatchToProps)(withLayout()(DataCompletionComponent));
