import classNames from 'classnames/bind';

import { Button } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ButtonsGroup = ({ mainText, altText, onMainClick, onAltClick, className }) => (
  <div className={cx('component', className)}>
    <Button onClick={onMainClick}>
      {mainText}
    </Button>

    <Button styleType="secondary" className={cx('altButton')} onClick={onAltClick}>
      {altText}
    </Button>
  </div>
);
