import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { useOnMount } from 'hooks/useOnMount';

import { getContent } from './helpers';

import { withLayout } from 'hocs/withLayout';

import { Text, Title } from 'ui';
import { DocumentLink, ThemedImage } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ResultPage = () => {
  const [query] = useSearchParams();

  const resultKey = query.get('key');
  const CONTENT = getContent(resultKey);

  useOnMount(() => {
    if (CONTENT.redirectTo) {
      setTimeout(() => {
        window.location.href = CONTENT.redirectTo;
      }, 5000);
    }
  });

  return (
    <>
      <ThemedImage
        alt=""
        width="120"
        height="120"
        name={CONTENT.icon}
      />

      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      {CONTENT.text && (
        <Text>
          {CONTENT.text}
        </Text>
      )}

      {CONTENT.texts?.map((textWithLink, index) => (
        <div key={index.toString()} className={cx('text')}>
          <DocumentLink {...textWithLink} />
        </div>
      ))}
    </>
  );
};

export const Result = withLayout()(ResultPage);
