import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';

import { CODE_VERIFICATION_ROUTE } from 'constants/routes';
import { CODE, MSISDN_KEY, PROMO_MESSAGES_CHECKBOX } from 'constants/forms';
import { AUTH_REG_ID_KEY } from 'constants/const';

import { confirmationRequest } from 'api/requests';

const mapValues = (values) => {
  const birthDateValue = `${values.birthdate.substring(4, 8)}-${values.birthdate.substring(2, 4)}-${values.birthdate.substring(0, 2)}`;

  return ({
    birthdate: birthDateValue,
  });
};

export const useBirthdateVerification = () => {
  const navigate = useNavigate();

  const { run: submit } = useRequest(confirmationRequest, {
    manual: true,
    throwOnError: true,
    onSuccess: ({ data }) => {
      sessionStorage.setItem(AUTH_REG_ID_KEY, data[AUTH_REG_ID_KEY]);
      sessionStorage.setItem(MSISDN_KEY, data[MSISDN_KEY]);
      navigate(CODE_VERIFICATION_ROUTE);
    },
  });

  const handleRequest = (values) => {
    submit({ code: sessionStorage.getItem(CODE), formData: mapValues(values), isConsent: values[PROMO_MESSAGES_CHECKBOX] });
  };

  return {
    onSubmit: handleRequest,
  };
};
