import { connect } from 'react-redux';

import { closeModal } from 'store/modal/actions';

import { BasePopupStateless } from '../Popup';

const ModalComponent = ({ className, children, onClose, ...restProps }) => (
  <BasePopupStateless
    className={className}
    onClose={onClose}
    isOpen
    {...restProps}
  >
    {children}
  </BasePopupStateless>
);

const mapDispatchToProps = {
  onClose: closeModal,
};

export const Modal = connect(null, mapDispatchToProps)(ModalComponent);
