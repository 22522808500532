import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const DigitContainer = ({ className, isActive, isFocused, children }) => (
  <div className={cx('component', className, { isActive, isFocused }, !children && 'isEmpty')}>
    <Text>
      {children}
    </Text>
  </div>
);

DigitContainer.propTypes = {
  isActive: PropTypes.bool,
};
