import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { setDateValue, getErrorMessage } from 'store/form';

import { valuesWithFiller, getInitialSlides } from './helpers';

import { useDateSwiper } from './useDateSwiper';

import { Text } from '../Text';
import { Button } from '../Button';
import { Swiper } from './Swiper';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const DateSwiperComponent = ({
  text,
  buttonText,
  monthsRange = valuesWithFiller.months,
  yearsRange = valuesWithFiller.years,
  daysRange = valuesWithFiller.days,
  onSubmit,
  onSetDateValue,
  errorMessage,
}) => {
  const {
    formValue,
    handleMonthSelect,
    handleYearSelect,
    handleDaySelect,
  } = useDateSwiper();

  const handleSubmit = useCallback(() => {
    if (!errorMessage) {
      onSetDateValue(formValue);
      onSubmit();
    }
  }, [formValue]);

  const { day, month, year } = useMemo(() => getInitialSlides(daysRange, yearsRange), [daysRange, yearsRange]);

  return (
    <div className={cx('component')}>
      <div className={cx('label')}>
        <Text size="l" isBold500>
          {text}
        </Text>
      </div>

      <div className={cx('container')}>
        <Swiper className={cx('months')} onSelect={handleMonthSelect} initialSlide={month} withPadding>
          {monthsRange}
        </Swiper>
        <Swiper onSelect={handleDaySelect} initialSlide={day} centered>
          {daysRange}
        </Swiper>
        <Swiper onSelect={handleYearSelect} initialSlide={year} centered>
          {yearsRange}
        </Swiper>
        <div className={cx('overlay')} />
      </div>

      <Button onClick={handleSubmit}>
        {buttonText}
      </Button>

      {errorMessage && (
        <Text className={cx('error')} isError isCentering>
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = {
  onSetDateValue: setDateValue,
};

export const DateSwiper = connect(mapStateToProps, mapDispatchToProps)(DateSwiperComponent);
