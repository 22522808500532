import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const DocumentLink = ({
  link,
  linkText,
  textBeforeLink,
  textAfterLink,
  textSize,
  download,
  target,
}) => (
  <>
    {textBeforeLink && (
      <>
        <Text isInline size={textSize}>
          {textBeforeLink}
        </Text>
        {' '}
      </>
    )}

    <Text isInline size={textSize}>
      <a
        download={download}
        href={link}
        target={target}
        rel="noreferrer"
        className={cx('link')}
      >
        {linkText}
      </a>
    </Text>

    {textAfterLink && (
      <>
        {' '}
        <Text isInline size={textSize}>
          {textAfterLink}
        </Text>
      </>
    )}
  </>
);

DocumentLink.defaultProps = {
  textSize: 'regular',
  target: '_blank',
  download: true,
};

DocumentLink.propTypes = {
  textAfterLink: PropTypes.string,
  textBeforeLink: PropTypes.string,
  textSize: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.bool,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};
