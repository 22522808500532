import { Input, Checkbox, Select, RadioButton } from 'ui';
import { PhoneInput, DateInput, CustomDateInput, AuthorityCodeInput, PassportInput } from './components';

import withFinalField from 'hocs/withFinalField';
import { withFieldCaption } from 'hocs/withFieldCaption';

export const FFInput = withFinalField(withFieldCaption(Input));
export const FFSelect = withFinalField(withFieldCaption(Select));
export const FFDateInput = withFinalField(withFieldCaption(DateInput));
export const FFCustomDateInput = withFinalField(withFieldCaption(CustomDateInput));
export const FFPhoneInput = withFinalField(withFieldCaption(PhoneInput));
export const FFCheckbox = withFinalField({ type: 'checkbox' })(withFieldCaption(Checkbox));
export const FFRadioButton = withFinalField({ type: 'radio' })(withFieldCaption(RadioButton));
export const FFAuthorityCodeInput = withFinalField(withFieldCaption(AuthorityCodeInput));
export const FFPassportInput = withFinalField(withFieldCaption(PassportInput));
