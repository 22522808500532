import classNames from 'classnames/bind';

import { CustomPropTypes } from 'utils/react';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Layout = ({ children }) => (
  <div className={cx('layout')}>
    {children}
  </div>
);

Layout.propTypes = {
  children: CustomPropTypes.children,
};
