import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { UPDATE_REQUEST as CONTENT } from 'constants/content';
import { DATA_UPDATE, CONFIRM_ROUTE } from 'constants/routes';

import { withLayout } from 'hocs/withLayout';

import { Title, Text } from 'ui';
import { ButtonsGroup, ThemedImage } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const UpdateRequestPage = () => {
  const navigate = useNavigate();

  const handleAccept = () => {
    navigate(DATA_UPDATE);
  };

  const handleDecline = () => {
    navigate(CONFIRM_ROUTE);
  };

  return (
    <>
      <ThemedImage
        name={CONTENT.icon}
        alt=""
        width="120"
        height="120"
        className={cx('image')}
      />

      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <Text className={cx('text')}>
        {CONTENT.text}
      </Text>

      <ButtonsGroup
        mainText={CONTENT.buttonAccept}
        altText={CONTENT.buttonDecline}
        onMainClick={handleAccept}
        onAltClick={handleDecline}
      />
    </>
  );
};

export const UpdateRequest = withLayout()(UpdateRequestPage);
