import {
  NEGATIVE_NOT_PASSPORT,
  NEGATIVE_NOT_AVAILABLE_18,
  NEGATIVE_NOT_AVAILABLE,
  NEGATIVE_DEFAULT,
  NEGATIVE_WRONG_CLAIMS,
} from 'constants/content';

export const STATUS_INFO = {
  LEGAL_SIM: 'legal_sim',
  WRONG_DOCUMENT: 'wrong_document',
  UNDERAGED_SIM: 'underaged_sim',
  DATA_FAILED: 'data_failed',
  WRONG_CLAIMS: 'wrong_claims',
};

export const getContent = (status) => {
  switch (status) {
    case STATUS_INFO.LEGAL_SIM:
      return NEGATIVE_NOT_AVAILABLE;

    case STATUS_INFO.WRONG_DOCUMENT:
      return NEGATIVE_NOT_PASSPORT;

    case STATUS_INFO.UNDERAGED_SIM:
      return NEGATIVE_NOT_AVAILABLE_18;

    case STATUS_INFO.DATA_FAILED:
      return NEGATIVE_DEFAULT;

    case STATUS_INFO.WRONG_CLAIMS:
      return NEGATIVE_WRONG_CLAIMS;

    default:
      return NEGATIVE_DEFAULT;
  }
};
