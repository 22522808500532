import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { AUTH as CONTENT } from 'constants/content';
import { AUTH_FORM, MSISDN_KEY } from 'constants/forms';

import { FFPhoneInput } from 'forms';
import { Button, Text, Title as _Title, Footer as _Footer } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Title = connect(() => ({
  className: cx('title'),
  level: 1,
  children: CONTENT.title,
}))(_Title);

export const Description = connect(() => ({
  className: cx('description'),
  children: CONTENT.description,
}))(Text);

export const PhoneInput = connect(() => ({
  ...AUTH_FORM[MSISDN_KEY],
  className: cx('phone-input'),
}))(FFPhoneInput);

export const SubmitButton = connect(() => ({
  className: cx('button'),
  type: 'submit',
  styleType: 'alternative',
  children: CONTENT.buttonText,
}))(Button);

export const Legal = connect(() => ({
  className: cx('legal-text'),
  size: 's',
  isSecondary: true,
  children: CONTENT.legal,
}))(Text);

export const Footer = connect(() => ({
  displayAbout: false,
}))(_Footer);
