import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { closeModal, getModalName } from 'store/modal';

import { DATE_INPUT_MODAL } from 'constants/content';

import { withRenderFlag } from 'hocs/withRenderFlag';

import { DateSwiper } from 'ui';
import { Modal } from '..';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const DateSwiperModalComponent = ({ onClose, ...restProps }) => (
  <Modal
    className={cx('component')}
    handleOutsideClick={false}
    onClose={onClose}
    {...restProps}
  >
    <DateSwiper
      {...DATE_INPUT_MODAL}
      onSubmit={onClose}
    />
  </Modal>
);

const mapStateToProps = (state, props) => ({
  shouldRender: getModalName(state) === props.name,
});

const mapDispatchToProps = {
  onClose: closeModal,
};

export const DateSwiperModal = connect(mapStateToProps, mapDispatchToProps)(withRenderFlag(DateSwiperModalComponent));
