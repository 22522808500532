import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Text = ({
  size,
  isError,
  children,
  isInline,
  isInverse,
  className,
  isBold500,
  isBold700,
  isSecondary,
  isCentering,
  isPlaceholder,
  ...props
}) => {
  const Tag = isInline ? 'span' : 'div';

  return (
    <Tag
      // classnames
      className={cx('component', className, size, {
        error: isError,
        inverse: isInverse,
        bold500: isBold500,
        bold700: isBold700,
        center: isCentering,
        secondary: isSecondary,
        placeholder: isPlaceholder,
      })}
      // check for need to use dangerouslySetInnerHTML
      {...(typeof children !== 'string'
        ? { children }
        : { dangerouslySetInnerHTML: { __html: children } }
      )
      }
      // other html attributes
      {...props}
    />
  );
};

Text.defaultProps = { size: 'regular' };

Text.propTypes = {
  isError: PropTypes.bool,
  className: PropTypes.any,
  children: PropTypes.node,
  isInline: PropTypes.bool,
  isInverse: PropTypes.bool,
  isBold700: PropTypes.bool,
  isBold500: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isCentering: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'regular', 'm', 'l']),
};
