import classNames from 'classnames/bind';

import { withRenderFlag } from 'hocs/withRenderFlag';

import { Text } from '../../../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const OptionsComponent = ({ className, options, onClick }) => (
  <div className={cx('component', className)}>
    <ul className={cx('container')}>
      {options.map((option) => (
        <li className={cx('option')} key={option.value}>
          <button className={cx('button')} type="button" onClick={() => onClick(option)}>
            <Text className={cx('text')}>
              {option.displayValue}
            </Text>
          </button>
        </li>
      ))}
    </ul>
  </div>
);

export const Options = withRenderFlag(OptionsComponent);
