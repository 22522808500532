import classNames from 'classnames/bind';

import { useSelect } from 'hooks/useSelect';

import { Options, PseudoInput } from './components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Select = ({ options, onChange, ...restProps }) => {
  const {
    isOpen,
    inputValue,
    inputDisplayValue,
    toggleOptions,
    onClick,
  } = useSelect({ onChange });

  return (
    <div className={cx('container')}>
      <PseudoInput
        {...restProps}
        isOpen={isOpen}
        value={inputValue}
        displayValue={inputDisplayValue}
        onClick={toggleOptions}
      />

      <Options shouldRender={options && isOpen} options={options} onClick={onClick} />
    </div>
  );
};
