import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import Arrow from 'icons/arrow.svg';

import { getCustomNav } from './helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { shouldRender, redirectTo } = getCustomNav(location);

  const handleBack = useCallback(() => {
    if (redirectTo) {
      navigate(redirectTo, { replace: true });
      return;
    }

    navigate(-1);
  }, [location]);

  return shouldRender ? (
    <button
      className={cx('component')}
      type="button"
      aria-label="Назад"
      onClick={handleBack}
    >
      <Arrow />
    </button>
  ) : null;
};
