import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Title = ({
  size,
  level,
  children,
  className,
  isCentering,
  ...props
}) => {
  const Tag = level ? `h${level}` : 'h2';

  return (
    <Tag
      className={cx('component', className, size, { center: isCentering })}
      {...(typeof children !== 'string'
        ? { children }
        : { dangerouslySetInnerHTML: { __html: children } }
      )
      }
      {...props}
    />
  );
};

Title.defaultProps = {
  size: 'l',
  level: 2,
};

Title.propTypes = {
  className: PropTypes.any,
  isCentering: PropTypes.bool,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  size: PropTypes.oneOf(['s', 'regular', 'm', 'l']),
};
