import { useAuth } from './useAuth';

import { FinalForm } from 'forms/FinalForm';
import { Layout } from 'ui';
import { Frame } from './Frame';
import { Title, Description, PhoneInput, SubmitButton, Legal, Footer } from './connected';

export const AuthStart = () => {
  const { onSubmit, onValidate } = useAuth();

  return (
    <Layout>
      <Frame>
        <Title />
        <Description />
        <FinalForm
          onSubmit={onSubmit}
          values={{}}
          getValidators={onValidate}
        >
          {({ invalid }) => (
            <>
              <PhoneInput />
              <SubmitButton disabled={invalid} />
            </>
          )}
        </FinalForm>
        <Legal />
      </Frame>
      <Footer />
    </Layout>
  );
};
