import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { CustomPropTypes } from 'utils/react';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Checkbox = ({
  id,
  name,
  size,
  value,
  children,
  disabled,
  isError,
  onBlur,
  onFocus,
  onChange,
  className,
  forwardedRef,
}) => {
  const handleBur = useCallback((e) => {
    onBlur?.(value, e);
  }, [value, onBlur]);

  const handleFocus = useCallback((e) => {
    onFocus?.(value, e);
  }, [value, onFocus]);

  const handleChange = useCallback((e) => {
    onChange?.(e.target.checked, e);
  }, [onChange]);

  return (
    <div className={cx('component', className)}>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={value}
        ref={forwardedRef}
        disabled={disabled}
        data-invalid={isError}
        className={cx('checkbox')}
        style={{
          width: size,
          height: size,
          minWidth: size,
        }}
        onBlur={handleBur}
        onFocus={handleFocus}
        onChange={handleChange}
      />

      {children && (
        <label htmlFor={id} className={cx('label')}>
          {children}
        </label>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  size: 24,
};

Checkbox.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  forwardedRef: CustomPropTypes.ref,
  children: CustomPropTypes.children,
  className: PropTypes.any,
};
