import { MaskedInput } from './MaskedInput';

const unmaskValue = (value) => value.replace(/\D/g, '');

export const PassportInput = ({ value = '', onChange, ...props }) => (
  <MaskedInput
    inputMode="tel"
    unmaskValue={unmaskValue}
    {...props}
    maxLength={11}
    value={value}
    onChange={(val, event) => onChange(val, event)}
  />
);
