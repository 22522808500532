import classNames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';

import { request } from 'api/wrapper';

import { PAYMENT_SUCCESS as CONTENT } from 'constants/content';

import { useOnMount } from 'hooks/useOnMount';

import { Text, Title } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Success = () =>
/* const [query] = useSearchParams();
  const code = query.get('code');
  const encodedData = btoa('skhtest');

  const requestMcToken = () => request('/mc-token', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${encodedData}:${encodedData}`,
    },
    data: new URLSearchParams({
      code,
      redirect_uri: 'https://aggrmobileiduat.beeline.ru/payment-success',
      grant_type: 'authorization_code',
    }),
  });

  useOnMount(requestMcToken); */

  (
    <div className={cx('component')}>
      <svg width="81" height="81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40.5" cy="40.5" r="40.5" fill="#A4D0E9" />
        {/* eslint-disable-next-line max-len */}
        <path d="M53.945 30.747c-.256.146-3.236 3.255-8.942 9.326l-8.558 9.106-4.279-4.333c-1.974-1.975-3.218-3.2-3.73-3.676-.475-.475-.805-.731-.987-.768-.512-.146-.988-.11-1.427.11-.402.183-.731.493-.987.932-.11.22-.183.403-.22.549v.494c.037.329.092.585.165.768.11.146 2.03 2.14 5.76 5.98 3.767 3.84 5.687 5.76 5.76 5.76.073 0 3.438-3.548 10.094-10.643 6.693-7.095 10.076-10.734 10.15-10.917.109-.183.164-.476.164-.878s-.092-.75-.275-1.042a1.398 1.398 0 0 0-.658-.713c-.256-.147-.585-.22-.987-.22-.403-.036-.75.019-1.043.165Z" fill="#fff" fillOpacity=".87" />
      </svg>

      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <Text className={cx('description')}>
        {CONTENT.description}
      </Text>

      <button type="button" className={cx('button')}>
        {CONTENT.buttonText}
      </button>
    </div>
  );
