import classNames from 'classnames/bind';

import Logo from 'icons/mobileIdLogo.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Frame = ({ children }) => (
  <section className={cx('component')}>
    <Logo className={cx('logo')} />

    <div className={cx('children')}>
      {children}
    </div>
  </section>
);
