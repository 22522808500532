import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import withToggle from 'hocs/withToggle';
import { useLockScroll } from 'hooks/useLockScroll';

import FixedOverlay from '../FixedOverlay';
import CloseIcon from '../../assets/close.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

/**
 * Stateless popup layout with screen overlay
 * Appears by sliding down from top of the screen,
 * ContentMapper has predefined size and fixed outer indents, but no padding
 */
export function BasePopupStateless({
  children,
  isOpen,
  isWide,
  onClose,
  className,
  hideCloseButton,
  hasScrollOverlay,
  overlayClassName,
  handleOutsideClick,
  animationStyle,
}) {
  useLockScroll(isOpen);

  return (
    <FixedOverlay
      className={cx('overlay', overlayClassName, animationStyle)}
      isOpen={isOpen}
      onClose={onClose}
      hasScrollOverlay={hasScrollOverlay}
      handleOutsideClick={handleOutsideClick}
    >
      <div className={cx('component', className, { wide: isWide })}>
        {!hideCloseButton && (
          <button className={cx('cross', 'popupCloseButton', { hideCloseButton })} onClick={onClose} type="button">
            <CloseIcon className={cx('icon')} />
          </button>
        )}
        {children}
      </div>
    </FixedOverlay>
  );
}

BasePopupStateless.defaultProps = {
  animationStyle: 'centered',
};

BasePopupStateless.propTypes = {
  isOpen: PropTypes.bool,
  isWide: PropTypes.bool,
  hasScrollOverlay: PropTypes.bool,
  overlayClassName: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  animationStyle: PropTypes.oneOf(['on-top', 'on-bottom', 'centered']),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

/**
 * One-use popup, initially open, it can only be closed once and for good
 * For all other use cases, please refer to Stateless version
 */
export const BasePopupDisposable = withToggle(BasePopupStateless);

BasePopupDisposable.defaultProps = {
  isOpen: true,
  handleOutsideClick: true,
};
