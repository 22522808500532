import classNames from 'classnames/bind';

import { OUTDATED_LINK as CONTENT } from 'constants/content';

import { withLayout } from 'hocs/withLayout';

import { ThemedImage } from 'components';
import { Text, Title } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const OutdatedLinkErrorPage = withLayout()(() => (
  <>
    <ThemedImage
      alt=""
      width="120"
      height="120"
      name={CONTENT.icon}
    />

    <Title level={1} className={cx('title')}>
      {CONTENT.title}
    </Title>

    <Text className={cx('text')}>
      {CONTENT.description}
    </Text>
  </>
));
