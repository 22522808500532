import React from 'react';
import ReactDOM from 'react-dom';
import smoothScroll from 'smoothscroll-polyfill';

import { AppHMR } from 'app';

import './index.css';

// kick off the polyfill!
smoothScroll.polyfill();

if (typeof window === 'object') {
  ReactDOM.render(
    <React.StrictMode>
      <AppHMR />
    </React.StrictMode>,
    document.querySelector('main#root'),
  );
}
