import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { AUTH as CONTENT } from 'constants/content';

import { withLayout } from 'hocs/withLayout';

import { Text, Title } from 'ui';
import { DocumentLink, ThemedImage } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const AuthResultPage = () => {
  const [query] = useSearchParams();
  const TEXTS = CONTENT[query.get('type')];

  return (
    <>
      <ThemedImage
        alt=""
        width="120"
        height="120"
        name={TEXTS.icon}
      />

      <Title level={1} className={cx('title')}>
        {TEXTS.title}
      </Title>

      {TEXTS.texts ? TEXTS.texts.map((textWithLink, index) => (
        <div key={index.toString()} className={cx('text')}>
          <DocumentLink
            {...textWithLink}
          />
        </div>
      )) : (
        <Text className={cx('text')}>
          {TEXTS.text}
        </Text>
      )}
    </>
  );
};

export const AuthResult = withLayout()(AuthResultPage);
