import { useTimer } from 'hooks/useTimer';

import { Button } from 'ui';

export const TimerButton = ({ text, className }) => {
  const { time, resetTime } = useTimer(59);
  const buttonText = time === 0 ? text : (time < 10 ? `00:0${time}` : `00:${time}`);

  return (
    <Button className={className} disabled={!!time} onClick={resetTime}>
      {buttonText}
    </Button>
  );
};
