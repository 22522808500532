import { CODE_VERIFICATION_ROUTE, ERROR_ROUTE, FAIL_ROUTE } from 'constants/routes';

const customRoutesNavigation = {
  [FAIL_ROUTE]: {
    shouldRender: true,
  },
  [CODE_VERIFICATION_ROUTE]: {
    shouldRender: true,
  },
  [ERROR_ROUTE]: {
    shouldRender: true,
  },
};

export const getCustomNav = ({ pathname, search }) => {
  const path = search ? `${pathname}${search}` : pathname;
  const customNavigation = customRoutesNavigation[path];

  return { shouldRender: customNavigation?.shouldRender, redirectTo: customNavigation?.redirectTo };
};
