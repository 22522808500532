import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { NUMBERS_LIST_UPDATE as CONTENT } from 'constants/content';
import { UPDATE_REQUEST } from 'constants/routes';

import { withLayout } from 'hocs/withLayout';

import { Title } from 'ui';
import { Form } from './Form';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const NumbersListUpdatePage = ({ numbers }) => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate(UPDATE_REQUEST);
  };

  return (
    <>
      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>
      <Form numbers={numbers} onSuccess={handleSuccess} />
    </>
  );
};

NumbersListUpdatePage.defaultProps = {
  numbers: [
    '+7 966 194-09-89',
    '+7 963 556-12-00',
    '+7 966 879-54-54',
    '+7 966 194-34-07',
  ],
};

export const NumbersListUpdate = withLayout()(NumbersListUpdatePage);
