import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { DEFAULT_ERROR } from 'constants/content';

import { withLayout } from 'hocs/withLayout';

import { Text, Title, Button } from 'ui';
import { ThemedImage } from '../../ThemedImage';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const _Error = () => {
  const navigate = useNavigate();

  const handleBack = () => { navigate(-1); };

  return (
    <>
      <ThemedImage
        alt=""
        width="120"
        height="120"
        name={DEFAULT_ERROR.icon}
      />

      <Title level={1} className={cx('title')}>
        {DEFAULT_ERROR.title}
      </Title>

      <Text className={cx('description')}>
        {DEFAULT_ERROR.description}
      </Text>

      <Button onClick={handleBack}>
        {DEFAULT_ERROR.buttonText}
      </Button>
    </>
  );
};

export const Error = withLayout()(_Error);
