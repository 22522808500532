import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { ANDROID } from 'constants/const';

import { usePlatform } from 'hooks/usePlatform';

import { CustomPropTypes } from 'utils/react';

export const BasicInput = ({
  id,
  name,
  value,
  isError,
  disabled,
  inputMode,
  placeholder,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  forwardedRef,
  ...DOMProps
}) => {
  const platform = usePlatform();

  const handleBur = useCallback((e) => {
    onBlur?.(value, e);
  }, [value, onBlur]);

  const handleFocus = useCallback((e) => {
    onFocus?.(value, e);
  }, [value, onFocus]);

  const handleChange = useCallback((e) => {
    // To fix Android bug https://issuetracker.google.com/issues/36951629
    if (platform === ANDROID && DOMProps.maxLength && e.target.value.length > +DOMProps.maxLength) return;

    onChange?.(e.target.value, e);
  }, [onChange, DOMProps.maxLength]);

  const handleKeyDown = useCallback((event) => {
    onKeyDown?.({ value: event.target.value, event });
  }, [onKeyDown]);

  return (
    <input
      id={id}
      name={name}
      type="text"
      autoComplete="on"
      ref={forwardedRef}
      value={value || ''}
      disabled={disabled}
      inputMode={inputMode}
      data-invalid={isError}
      placeholder={placeholder}
      {...DOMProps}
      onBlur={handleBur}
      onFocus={handleFocus}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

BasicInput.defaultProps = { inputMode: 'text' };

BasicInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  forwardedRef: CustomPropTypes.ref,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  inputMode: PropTypes.oneOf([
    'none', 'text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url',
  ]),
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};
