import { Children, cloneElement } from 'react';

export const RadioGroup = ({ name, value, disabled, onChange, className, children }) => (
  <div className={className}>
    {Children.toArray(children).map((child) => (
      child && cloneElement(child, {
        name,
        disabled: disabled || child.props.disabled,
        checked: value === child.props.value,
        onChange,
      })
    ))}
  </div>
);
