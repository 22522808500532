import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { isScreenBelow640 } from 'store/adaptivity/selectors';

import { withRenderFlag } from 'hocs/withRenderFlag';

import { Text } from '../../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const landing = 'https://mobileid.beeline.ru/b2c';

const _AboutService = ({ className }) => (
  <a href={landing} target="_blank" rel="noreferrer" className={cx('link', className)}>
    <Text isSecondary isInline>
      О сервисе
    </Text>
  </a>
);

const mapStateToProps = (state) => ({
  shouldRender: !isScreenBelow640(state),
});

export const AboutService = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(_AboutService);
