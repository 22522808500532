import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { AUTH as CONTENT } from 'constants/content';
import { MSISDN_KEY } from 'constants/forms';

import { formatPhone } from 'utils/format-string';

import { withLayout } from 'hocs/withLayout';

import { ThemedImage } from 'components';
import { Text, Title, Tooltip } from 'ui';
import { TimerButton } from '../components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ConfirmAuthPage = () => {
  const [query] = useSearchParams();

  const TEXTS = CONTENT[query.get('type')];
  const msisdn = formatPhone(sessionStorage.getItem(MSISDN_KEY));

  return (
    <>
      <Title level={1} className={cx('title')}>
        {TEXTS.title}
      </Title>

      <Text className={cx('description')}>
        {TEXTS.description}
      </Text>

      <div className={cx('method')}>
        <div>
          <Text className={cx('text')} isBold500>
            {TEXTS.method}
          </Text>
          <Text className={cx('text')}>
            {' '}
            {TEXTS.text}
          </Text>
          <Text className={cx('text')}>
            {' '}
            {msisdn}
          </Text>

          {TEXTS.tooltip && (
            <Tooltip
              className={cx('tooltip')}
              width={229}
              position="center"
              text={TEXTS.tooltip?.value}
              label={TEXTS.tooltip?.label}
            />
          )}
        </div>

        <ThemedImage
          alt=""
          width="120"
          height="120"
          name={TEXTS.icon}
          className={cx('image')}
        />
      </div>

      <TimerButton className={cx('button')} text={TEXTS.buttonText} />
    </>
  );
};

export const ConfirmAuth = withLayout()(ConfirmAuthPage);
