import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { resetForm } from 'store/form';

import { DATA_UPDATE as CONTENT } from 'constants/content';
import { NUMBERS_LIST_UPDATE } from 'constants/routes';
import { SUBSCRIBER_INFO, UPDATE_FORM } from 'constants/forms';

import { withLayout } from 'hocs/withLayout';

import { Button, Title } from 'ui';
import { DynamicForm, DynamicFormField } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const formFields = [
  SUBSCRIBER_INFO.FAMILY_NAME,
  SUBSCRIBER_INFO.GIVEN_NAME,
  SUBSCRIBER_INFO.MIDDLE_NAME,
  SUBSCRIBER_INFO.EMAIL,
  SUBSCRIBER_INFO.BIRTH_DATE,
];

const DataUpdateComponent = ({ onResetForm }) => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    onResetForm();
    navigate(NUMBERS_LIST_UPDATE);
  };

  return (
    <>
      <Title level={1} className={cx('title')}>
        {CONTENT.title}
      </Title>

      <DynamicForm
        formFields={formFields}
        onSubmit={handleSuccess}
        button={(
          <Button type="submit" className={cx('button')}>
            {CONTENT.saveButton}
          </Button>
        )}
        validators={() => []}
      >
        {(field, index) => (
          <DynamicFormField
            field={field}
            key={index.toString()}
            className={cx('input')}
            {...UPDATE_FORM[field]}
          />
        )}
      </DynamicForm>
    </>
  );
};

const mapDispatchToProps = {
  onResetForm: resetForm,
};

export const DataUpdate = connect(null, mapDispatchToProps)(withLayout()(DataUpdateComponent));
