import classNames from 'classnames/bind';

import { Preloader } from '../../../Preloader';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Digits = ({ isLoading, children }) => (
  <div className={cx('component')}>
    {isLoading ? <Preloader /> : children}
  </div>
);
