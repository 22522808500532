import { Children } from 'react';
import classNames from 'classnames/bind';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Text } from '../../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const slidesPerView = 5;

export const Swiper = ({ onSelect, className, children, initialSlide, withPadding, centered }) => {
  const handleSlideChange = ({ activeIndex }) => {
    onSelect?.(children[activeIndex]);
  };

  return (
    <SwiperComponent
      className={cx('component', className)}
      slidesPerView={slidesPerView}
      direction="vertical"
      centeredSlidesBounds
      centeredSlides
      grabCursor
      onSlideChange={handleSlideChange}
      cssMode
      initialSlide={initialSlide}
      preventInteractionOnTransition
      speed={700}
    >
      {Children.map(children, (child) => (
        <SwiperSlide className={cx('wrapper')}>
          <Text className={cx('value', { centered }, withPadding && 'with-padding')}>
            {child}
          </Text>
        </SwiperSlide>
      ))}
    </SwiperComponent>
  );
};
