import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from '../Text';
import { BasicInput } from '../BasicInput.jsx';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Input = ({ label, value, preventLabelAnimation, ...DOMProps }) => (
  <label className={cx('component')}>
    <BasicInput
      className={cx('input', value && 'input-not-empty', { preventLabelAnimation })}
      {...DOMProps}
      value={value}
    />

    <Text
      isInline
      isPlaceholder
      className={cx('label')}
    >
      {label}
    </Text>
  </label>
);

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preventLabelAnimation: PropTypes.bool,
};
