import { getValidators } from 'utils/form';
import { getCookie } from 'utils/cookie';
import { composeDateValidator, composeEmailValidator, composeNameValidator } from 'utils/validators';

import { SITE_REDIRECT_URL_COOKIE, SITE_WRONG_CLAIMS_COOKIE } from 'constants/const';
import { DATA_COMPLETION_TEXTS } from 'constants/content';
import { FORM_VALIDATION, SUBSCRIBER_INFO } from 'constants/forms';

export const formFields = getCookie(SITE_WRONG_CLAIMS_COOKIE)?.split(',') || [];
export const redirectUrl = getCookie(SITE_REDIRECT_URL_COOKIE);
export const TEXTS = DATA_COMPLETION_TEXTS;

const validators = ({
  [SUBSCRIBER_INFO.GIVEN_NAME]: [composeNameValidator(FORM_VALIDATION[SUBSCRIBER_INFO.GIVEN_NAME])],
  [SUBSCRIBER_INFO.FAMILY_NAME]: [composeNameValidator(FORM_VALIDATION[SUBSCRIBER_INFO.FAMILY_NAME])],
  [SUBSCRIBER_INFO.MIDDLE_NAME]: [composeNameValidator(FORM_VALIDATION[SUBSCRIBER_INFO.MIDDLE_NAME])],
  [SUBSCRIBER_INFO.EMAIL]: [composeEmailValidator(FORM_VALIDATION[SUBSCRIBER_INFO.EMAIL])],
  [SUBSCRIBER_INFO.BIRTH_DATE]: [composeDateValidator(FORM_VALIDATION.date)],
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE]: [composeDateValidator(FORM_VALIDATION.date)],
});

export const formValidators = () => getValidators(formFields, validators);

export const timeMessage = (time) => {
  const messageTime = new Date(time * 1000);

  const seconds = () => {
    const timeSeconds = messageTime.getSeconds();

    return timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds;
  };

  return time === 0 ? '' : `${messageTime.getMinutes() > 0 ? messageTime.getMinutes() : '0'}:${seconds()}`;
};