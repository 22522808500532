export const calculateInitialBias = (width, position) => {
  let bias = width / -2;

  if (position === 'right') {
    bias -= width / 2 - 16;
  } else if (position === 'left') {
    bias += width / 2 - 16;
  }

  return bias;
};

export const calculateBiasDiff = (element) => {
  let diff = 0;
  const { left, right } = element.getBoundingClientRect();

  if (left < 0) {
    diff += (left - 12) * -1;
  } else if (right > window.innerWidth) {
    diff -= right - window.innerWidth + 12;
  }

  return diff;
};