import { Component } from 'react';
import { Error } from './Error';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: undefined };
  }

  componentDidCatch(error) {
    this.setState({ hasError: !!error, message: error });
  }

  render() {
    const { children } = this.props;
    const { hasError, message } = this.state;

    if (hasError) {
      console.warn(`Hey, here is your error: ${message}`);
      return <Error />;
    }

    return children;
  }
}

export { ErrorBoundary };
