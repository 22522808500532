import classNames from 'classnames/bind';

import { INSUFFICIENT_BALANCE_WARNING } from 'constants/content';

import { Text } from 'ui';
import WarningIcon from './warning.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const BalanceWarning = ({ className }) => (
  <div className={cx('component', className)}>
    <WarningIcon className={cx('icon')} />

    <Text className={cx('text')}>
      {INSUFFICIENT_BALANCE_WARNING}
    </Text>
  </div>
);
