import { MaskedInput } from 'forms/components';

export const DigitInput = ({ className, ...restProps }) => (
  <MaskedInput
    className={className}
    pattern="[0-9]*"
    maxLength={4}
    mask="____"
    inputMode="tel"
    unmaskValue={(v) => v.replace(/\D/g, '')}
    {...restProps}
  />
);
