import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { isScreenBelow640 } from 'store/adaptivity/selectors';

import { Mobile, Desktop } from './components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const _ContentFrame = ({ isMobile, children }) => {
  const Component = isMobile ? Mobile : Desktop;

  return (
    <section className={cx('component')}>
      <Component>
        {children}
      </Component>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow640(state),
});

export const ContentFrame = connect(mapStateToProps)(_ContentFrame);
