import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getIsLoading } from 'store/global';

import { BackButton } from '../BackButton';
import { LoadingMessage } from '../LoadingMessage';
import Logo from '../logo.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const _Desktop = ({ isLoading, children }) => (
  <>
    <BackButton />

    <Logo className={cx('logo')} />

    <div className={cx('component')}>
      <LoadingMessage shouldRender={isLoading} />

      <div className={cx('children', { loading: isLoading })}>
        {children}
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
});

export const Desktop = connect(mapStateToProps)(_Desktop);
