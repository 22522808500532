import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { detectDarkTheme } from 'utils/detectDarkTheme';

export const ThemedImage = ({ name, alt = '', ...restProps }) => {
  const postfix = useMemo(() => (detectDarkTheme() ? '-dark' : ''), []);
  const src = useMemo(() => `https://static.beeline.ru/upload/MobileID/images/aggregator/${name}${postfix}.png`, [name]);

  return (
    <img src={src} alt={alt} {...restProps} />
  );
};

ThemedImage.propTypes = {
  name: PropTypes.string,
  alt: PropTypes.string,
};
