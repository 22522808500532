import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text, Tooltip } from 'ui';
import EyeOpen from 'icons/eye_open.svg';
import EyeClosed from 'icons/eye_closed.svg';
import QuestionIcon from 'icons/questionMark.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Detail = ({ label, tooltip, hideValue, children, className }) => {
  const [isHidden, setHidden] = useState(hideValue);

  const toggleValue = useCallback(() => setHidden((prev) => !prev), [hideValue]);

  return (
    <div
      className={cx('component', { clickable: hideValue }, className)}
      onClick={hideValue ? toggleValue : undefined}
    >
      <div className={cx('label-container')}>
        <Text className={cx('label')}>
          {label}
        </Text>
        {tooltip && (
          <Tooltip
            className={cx('tooltip')}
            width={285}
            position="center"
            text={tooltip?.text}
            label={<QuestionIcon />}
          />
        )}
      </div>

      {isHidden ? (
        <Text size="m">
          ***** ₽
        </Text>
      ) : children}

      {hideValue && (
        <div className={cx('icon')}>
          {isHidden ? <EyeOpen /> : <EyeClosed />}
        </div>
      )}
    </div>
  );
};

Detail.propTypes = {
  label: PropTypes.string,
  hideValue: PropTypes.bool,
};
