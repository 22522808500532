import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const RadioButton = ({
  id,
  name,
  size,
  value,
  checked,
  disabled,
  isError,
  className,
  ...restProps
}) => (
  <input
    {...restProps}
    disabled={disabled}
    id={id}
    data-invalid={isError}
    className={cx('radio', className)}
    name={name}
    type="radio"
    value={value}
    checked={checked}
    style={{
      width: size,
      height: size,
      minWidth: size,
    }}
  />
);

RadioButton.defaultProps = {
  size: 24,
};

RadioButton.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.any,
};
