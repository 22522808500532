import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Preloader = ({ className }) => (
  <div className={cx('component', className)} />
);

Preloader.propTypes = {
  className: PropTypes.string,
};
