import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MONTHS } from 'constants/dates';
import { FORM_VALIDATION } from 'constants/forms';

import { getErrorMessage, getFormField, setErrorMessage, setFormValue } from 'store/form';
import { resetErrorMessage, setMonth, setDay, setYear } from 'store/form/actions';

import { formatDate, validate } from './helpers';

export const useDateSwiper = () => {
  const dispatch = useDispatch();
  const isError = useSelector(getErrorMessage);
  const form = useSelector(getFormField) || {};

  const handleMonthSelect = useCallback((value) => {
    if (form.month?.label === value) return;

    dispatch(setMonth({
      label: value,
      index: MONTHS.indexOf(value) + 1,
    }));
  }, [setFormValue]);

  const handleDaySelect = useCallback((value) => {
    if (form.day === value) return;

    dispatch(setDay(value));
  }, [setFormValue, form, isError]);

  const handleYearSelect = useCallback((value) => {
    if (form.year === value) return;

    dispatch(setYear(value));
  }, [setFormValue]);

  useEffect(() => {
    if (!form.year) return;

    if (!validate(form) && !isError) {
      dispatch(setErrorMessage(FORM_VALIDATION.date));
      return;
    }

    if (isError && validate(form)) {
      dispatch(resetErrorMessage());
    }
  }, [form, isError]);

  return {
    formValue: formatDate(form),
    handleMonthSelect,
    handleYearSelect,
    handleDaySelect,
  };
};
