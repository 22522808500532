import { useCallback, useState } from 'react';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { usePollingConfirmation } from 'hooks/usePollingConfirmation';

import { CODE_FAIL_ROUTE, SUCCESS_ROUTE } from 'constants/routes';
import { CODE_VERIFICATION } from 'constants/content';
import { AUTH_REG_ID_KEY, SITE_CONSENT_WITH_OTP } from 'constants/const';
import { MSISDN_KEY } from 'constants/forms';

import { codeVerificationRequest } from 'api/requests';

import { templateParser } from 'utils/format-string';
import { getCookie } from 'utils/cookie';

const clearSessionStorage = () => {
  sessionStorage.removeItem(AUTH_REG_ID_KEY);
  sessionStorage.removeItem(MSISDN_KEY);
};

export const useCodeVerification = () => {
  const { startPolling } = usePollingConfirmation(clearSessionStorage);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const withOTP = getCookie(SITE_CONSENT_WITH_OTP)?.toLowerCase() === 'true';
  const authReqId = sessionStorage.getItem(AUTH_REG_ID_KEY);
  const ctn = sessionStorage.getItem(MSISDN_KEY);

  const resetError = useCallback(() => setError(''), []);

  const handleAttemptsError = useCallback((count) => {
    if (count > 0) {
      setError(`${templateParser(CODE_VERIFICATION.verificationError, { attempts: count })}`);
    }

    if (count === 0) {
      resetError();
      navigate(CODE_FAIL_ROUTE);
    }
  }, []);

  const { run: submit } = useRequest(codeVerificationRequest, {
    manual: true,
    throwOnError: true,
    onBefore: () => {
      if (error) {
        resetError();
      }
    },
    onSuccess: ({ data }) => {
      if (typeof data.attempts_count === 'number') {
        handleAttemptsError(data.attempts_count);
        return;
      }
      if (withOTP) {
        startPolling(authReqId);
      } else {
        clearSessionStorage();
        navigate(SUCCESS_ROUTE);
      }
    },
  });

  const handleRequest = ({ digits }) => {
    submit({ otp: digits, authReqId });
  };

  return {
    ctn: `+${ctn}`,
    onSubmit: handleRequest,
    error,
  };
};
