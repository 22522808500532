import { useMemo } from 'react';
import classNames from 'classnames/bind';

import { FinalForm } from 'forms/FinalForm';

import { getValidators, getDefaultValues } from 'utils/form';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const DynamicForm = ({
  children,
  formFields,
  defaultValues,
  validators,
  onSubmit,
  button,
  className,
  wrapperClassName,
}) => {
  const currentDefaults = useMemo(() => defaultValues || getDefaultValues(formFields), [formFields, defaultValues]);
  const currentValidators = useMemo(() => validators || getValidators(formFields), [formFields, validators]);

  return (
    <FinalForm
      onSubmit={onSubmit}
      values={currentDefaults}
      getValidators={currentValidators}
    >
      <div className={cx('inputs', className)}>
        <div
          className={cx('wrapper', wrapperClassName)}
          style={{ animationDuration: `${formFields.length * 140}ms` }}
        >
          {formFields.map((field, index) => children(field, index))}
        </div>
      </div>

      {button}
    </FinalForm>
  );
};
