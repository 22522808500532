import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Text } from 'ui';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Timer = ({ message, textClassName, icon, className }) => (
  <div className={cx('component', className)}>
    {icon}
    <Text className={cx('time-left', textClassName)}>
      {message}
    </Text>
  </div>
);

Timer.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string,
  textClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
