import classNames from 'classnames/bind';

import { DEFAULT_ERROR } from 'constants/content';

import { ThemedImage } from 'components';
import { Text, Title } from 'ui';

import styles from './styles.pcss';
import { withLayout } from 'hocs/withLayout';
const cx = classNames.bind(styles);

const DefaultError = () => (
  <>
    <ThemedImage
      alt=""
      width="120"
      height="120"
      name={DEFAULT_ERROR.icon}
    />

    <Title level={1} className={cx('title')}>
      {DEFAULT_ERROR.title}
    </Title>

    <Text>
      {DEFAULT_ERROR.text}
    </Text>
  </>
);

export default withLayout()(DefaultError);
